import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CRow,
  CButton,
} from '@coreui/react'
import { FaArrowLeft } from 'react-icons/fa'
import { ImageUrl } from 'src/config/config';

function view() {
  const { state } = useLocation()
  const { id } = useParams()
  const [packagePlanData, setPackagePlanData] = useState([]);
  const [packageServiceData, setPackageServiceData] = useState([]);
  //display the item in this package
  const [packageItemData, setPackageItemData] = useState([]);
  const [packageDuration, setPackageDuration] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceQuota, setServiceQuota] = useState();
  const size = [];
  const price = [];
  const quantity = [];
  const delivery = [];

  const packagePlan = () => {
    get(`admin/subscription/view-plan?_id=${atob(id)}`).then((data) => {
      if (data?.data !== null) {
        const tempData = data?.data?.map((item, key) => {
          var dataArr = [];
          dataArr?.push(item?.services.val)
          dataArr?.forEach((value, index) => {
            var keyValue = Object.keys(value)
            setServiceQuota(item?.quotaDetails);
            var ExistSizeId = Object.keys(item?.services.val);
            var packPlanData = Object.values(item?.services.val);
            var packPlanData1 = Object.entries(item?.services.val);
            if (ExistSizeId?.find(element => element == keyValue[0])) {
              setServiceData(packPlanData[0])
            }
          })
          var entry;
          var name;
          var count;
          entry = dataArr[0];
          count = 0;
          for (name in entry) {
            ++count;
          }
          var keyData = Object.keys(dataArr[0]).find(key => {
            // console.log(key)
            // if(key === 'enkey'){
            //   console.log("yesss")
            // }
          }
          )
          Object.values(dataArr[0]).map((data, key) => {
            // console.log(data)
          })


          let Data = Object.values(item?.services.val).map((name, key) => {
            name.forEach((ele, index) => {
              // console.log("map data",ele.size)
            })
          })

          setPackagePlanData(item)

          item?.subscribedurations.map((data, key) => {
            setPackageDuration(data)
          })

          setPackageItemData(item?.item)
          setPackageServiceData(item?.services)
          // let serviceDetail=[];
          // serviceDetail.push(item?.services.val)
        })
      }
    })
  }

  useEffect(() => {
    packagePlan();
  }, [state])
  return (
    <>
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <CRow>
                    <CCol >
                      {' '}
                      <Link style={{ color: '#000' }} to={{ pathname: `/admin/subscription-plan/package` }}>
                        <FaArrowLeft />
                      </Link>
                      <span>  Subscription Plan Information</span>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CRow>

                    <CCol>
                      <CRow>
                        <CCol md={2}>
                          <strong>Subscription Type :-</strong>
                        </CCol>
                        <CCol>
                          <CButton size="sm" outline={packagePlanData?.subcribe_type ? 'Regular Plan' : 'Package Plan'}>
                            <span>{packagePlanData?.subcribe_type == '1' ? 'Package Plan' : 'Regular Plan'}</span>
                          </CButton>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md={2}>
                          <strong>Package Name :-</strong>
                        </CCol>
                        <CCol>
                          <span>{packagePlanData?.packageName_EN}</span>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md={2}>
                          <strong>Package Description (EN) :-</strong>
                        </CCol>
                        <CCol>
                          <span dangerouslySetInnerHTML={{ __html: packagePlanData?.description_EN }} />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md={2}>
                          <strong>Package Description (TH) :-</strong>
                        </CCol>
                        <CCol>
                          <span dangerouslySetInnerHTML={{ __html: packagePlanData?.description_TH }} />
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md={2}>
                          <strong>Subcribe Duration :-</strong>
                        </CCol>
                        <CCol>
                          <span>{packageDuration?.title_EN}</span>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md={2}>
                          <strong>Services :-</strong>
                        </CCol>
                        <CCol>
                          <span>{packageServiceData?.serviceName_EN}</span>
                        </CCol>
                      </CRow>

                      {/* <CRow>
                        <CCol md={2}>
                          <strong>Items :-</strong>
                        </CCol>
                        <CCol>
                          {packageItemData.map((value, key) => {
                            return value?.itemName_EN
                            // return (<span>{value?.itemName_EN},</span>)
                          })}
                        </CCol>
                      </CRow> */}
                    </CCol>
                    <CCol md={2}>
                      <CRow>
                        <CCol>
                          <div className="myImges row">

                            <div className="col-md-10">
                              <figure>
                                <img src={`${ImageUrl}${packagePlanData?.image}`} alt='img' />
                                <figcaption>
                                  {/* <button type="button"><FaTrash onClick={() => deleteParticularFile(index)} style={{ color: 'red' }} /></button> */}
                                </figcaption>
                              </figure>
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                  {/* {console.log(packagePlanData?.image, "pack plan data")} */}
                  <CRow>

                  </CRow>


                  <CRow className='mt-4'>
                    <CCol>
                      <div className="card">
                        <div className="card-body">
                          <strong>Services Plan Details</strong>
                          <hr />
                          {serviceData?.map((val, key) => {
                            return (
                              <CRow className='mt-2'>
                                <CCol>

                                  <div>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Title (EN) :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{key == 0 ? packagePlanData.small_pack_title_EN : key == 1 ? packagePlanData.medium_pack_title_EN : key == 2 ? packagePlanData.large_pack_title_EN : ""}</span>
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Title (TH) :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{key == 0 ? packagePlanData.small_pack_title_TH : key == 1 ? packagePlanData.medium_pack_title_TH : key == 2 ? packagePlanData.large_pack_title_TH : ""}</span>
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Description (EN) :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span dangerouslySetInnerHTML={{ __html: key == 0 ? packagePlanData.small_pack_description_EN : key == 1 ? packagePlanData.medium_pack_description_EN : key == 2 ? packagePlanData.large_pack_description_EN : "" }} />
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Description (TH) :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span dangerouslySetInnerHTML={{ __html: key == 0 ? packagePlanData.small_pack_description_TH : key == 1 ? packagePlanData.medium_pack_description_TH : key == 2 ? packagePlanData.large_pack_description_TH : "" }} />
                                      </CCol>
                                    </CRow>

                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Plan Sizes :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{val.size}</span>
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Plan prices :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{val?.price}</span>
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Plan Delivery :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{val?.delivery}</span>
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Plan Quantity :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{val?.quantity}</span>
                                      </CCol>
                                    </CRow>
                                    <CRow>
                                      <CCol md={2}>
                                        <strong>Quota Details :-</strong>
                                      </CCol>
                                      <CCol>
                                        {key == 0 ? <ul >
                                          {serviceQuota?.s?.map((itemQuota, index) => (
                                            <> <li key={index}>{itemQuota?.name_EN} - {itemQuota?.quota}</li>
                                            </>
                                          ))}
                                        </ul> : key == 1 ? <ul >
                                          {serviceQuota?.m?.map((itemQuota, index) => (
                                            <> <li key={index}>{itemQuota?.name_EN} - {itemQuota?.quota}</li>
                                              {/* <li key={index}>{itemQuota?.quota}</li> */}
                                            </>
                                          ))}
                                        </ul> : key == 2 ? <ul >
                                          {serviceQuota?.l?.map((itemQuota, index) => (
                                            <> <li key={index}>{itemQuota?.name_EN} - {itemQuota?.quota}</li>
                                            </>
                                          ))}
                                        </ul> : ""}
                                      </CCol>
                                    </CRow>
                                    <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Items :-</strong>
                                      </CCol>
                                      <CCol>
                                        <span>{val?.item?.length > 0 && val?.item?.map((re) => {
                                          return (
                                            re?.itemName_EN + " , "
                                          )
                                        })}</span>
                                      </CCol>
                                    </CRow>
                                    {/* <CRow className='mt-2'>
                                      <CCol md='2'>
                                        <strong>Image :-</strong>
                                      </CCol>

                                      <CCol>
                                        <span>
                                          <img width={"50px"} src={key == 0 ? ImageUrl + "/" + packagePlanData.small_pack_image : key == 1 ? ImageUrl + "/" + packagePlanData.medium_pack_image : key == 2 ? ImageUrl + "/" + packagePlanData.large_pack_image : ""} />
                                        </span>

                                      </CCol>
                                    </CRow> */}

                                  </div>

                                </CCol>
                                {

                                }
                                <CCol md={2}>
                                  <CRow>
                                    <CCol>
                                      <div className="myImges row">

                                        <div className="col-md-10">
                                          <figure>
                                            <img src={key == 0 ? ImageUrl + "/" + packagePlanData.small_pack_image : key == 1 ? ImageUrl + "/" + packagePlanData.medium_pack_image : key == 2 ? ImageUrl + "/" + packagePlanData.large_pack_image : ""} alt='img' />
                                            <figcaption>
                                              {/* <button type="button"><FaTrash onClick={() => deleteParticularFile(index)} style={{ color: 'red' }} /></button> */}
                                            </figcaption>
                                          </figure>
                                        </div>
                                      </div>
                                    </CCol>
                                  </CRow>
                                </CCol><hr />
                              </CRow>
                            )

                          })}


                          {/* {console.log(serviceData, "serviceData")} */}
                          {/* <CRow className='mt-2'>
                            <CCol md='2'>
                              <strong>Plan Sizes :-</strong>
                            </CCol>
                            <CCol>
                              <span>{size?.join(',')}</span>
                            </CCol>
                          </CRow>
                          <CRow className='mt-2'>
                            <CCol md='2'>
                              <strong>Plan prices :-</strong>
                            </CCol>
                            <CCol>
                              <span>{price?.join(',')}</span>
                            </CCol>
                          </CRow>
                          <CRow className='mt-2'>
                            <CCol md='2'>
                              <strong>Plan Delivery :-</strong>
                            </CCol>
                            <CCol>
                              <span>{delivery?.join(',')}</span>
                            </CCol>
                          </CRow>
                          <CRow className='mt-2'>
                            <CCol md='2'>
                              <strong>Plan Quantity :-</strong>
                            </CCol>
                            <CCol>
                              <span>{quantity?.join(',')}</span>
                            </CCol>
                          </CRow> */}
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default view

