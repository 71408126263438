import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { post, get, deleteApi } from '../../utils/apiManager'
import CIcon from '@coreui/icons-react'
import { cilPen, cilPlus, cilTrash } from '@coreui/icons'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeader,
  CCardSubtitle,
  CTable,
  CRow,
  CImage,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CFormLabel,
} from '@coreui/react'
import { ToastContainer, toast } from 'react-toastify'
import Pagination from 'react-js-pagination'
import { FaRegEye, FaEdit, FaTrash } from 'react-icons/fa'
import { DefaultInput, DefaultInputWithIcon } from 'src/components/common/input'

function index() {
  const navigate = useNavigate()
  const [packageSubscription, setPackageSubscription] = useState()
  const deliveryBannerFileRef = useRef(null)
  const [searchItem, setsearchItem] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [deliveryBannerImageUrl, setDeliveryBannerImageUrl] = useState('')
  const [invalidDeliveryBannerImageNew, setInvalidDeliveryBannerImageNew] = useState('')
  const [diveryBannerImagePreview, setDeliveryBannerImagePreview] = useState("");
  const [pageData, setPageData] = useState({
    page: 1,
    perPage: 10,
    searchItem: '',
  })
  const [deletedArray, setDeletedArray] = useState([])
  const getData = () => {
    get(
      `/admin/subscription/get?page=${pageData?.page || 1}&perPage=${pageData?.perPage || 10
      }&searchItem=${pageData?.searchItem || ''}`,
    ).then((data) => handleResponse(data))
  }
  const handleResponse = (data) => {
    if (!data?.status) toast.success(data?.message, data?.status)
    else {
      // console.log(data?.data[0]?.data)
      setPackageSubscription(data?.data[0]?.data)
      setTotalPages(data?.data[0]?.metadata[0]?.page)
      setCount(data?.data[0]?.metadata[0]?.total)
    }
  }

  const changePage = (index) => {
    setPageData({
      ...pageData,
      page: index,
    })
  }
  const onSearchCLick = () => {
    setPageData({
      ...pageData,
      searchItem: searchItem,
    })
  }
  const statusChange = (status, id) => {
    let default_status = ''
    if (status == 0) default_status = 'Active'
    else default_status = 'Inactive'
    if (confirm(`Are you sure you want to ${default_status} the package plan`)) {
      let formData = {
        _id: id,
      }
      post(
        `${status ? '/admin/subscription/inactive' : '/admin/subscription/active'}`,
        formData,
      ).then((data) => {
        handleStatusChange(data)
      })
    }
  }
  const handleStatusChange = (data) => {
    toast.success(data?.message, data?.status)
    getData()
  }
  const onClickUserEdit = async (value) => {
    navigate('/admin/subscription-plan/package/edit', { state: value })
  }
  const deleteData = (id) => {
    if (confirm('Are you sure')) {
      let formData = {
        _id: id,
      }
      deleteApi(`/admin/subscription/delete?_id=${id}`).then((data) => {
        handleDelete(data)
      })
    }
  }

  const deliveryBannerHandler = (data) => {
    const formData = new FormData();
    formData.append('deliveryBanner', data);
    post(`admin/subscription/delivery-image`, formData, 1).then((res) => {
      if (res.status) {
        toast.success(res?.message)
      }
    }
    )
  }
  const handleDelete = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
    } else {
      toast.error(data?.message)
    }
    // toast.success(data?.message, data?.status);
    getData()
  }
  const onClickUserView = (value) => {
    navigate(`/admin/subscription-plan/package/view/${btoa(value._id)}`, { state: value })
  }
  //   const buttonClick = () => {
  //     addNotification({
  //         title: 'Warning',
  //         subtitle: 'This is a subtitle',
  //         message: 'This is a very long message',
  //         theme: 'darkblue',
  //         native: true // when using native, your OS will handle theming.
  //     });
  // };
  useEffect(() => {
    getData()
    getSettings()
  }, [pageData])

  const handleCheck = (id) => {
    let findValue = deletedArray.includes(id)
    if (findValue) {
      let filterData = deletedArray?.filter((item) => item != id)
      setDeletedArray(filterData)
    } else {
      setDeletedArray((prev) => [...prev, id])
    }
  }

  const deleteOrders = () => {
    post(`admin/subscription/bulk-delete`, { ids: deletedArray }).then((data) =>
      handleDeletedResponse(data),
    )
  }

  const handleDeletedResponse = (data) => {
    if (data?.status == true) {
      toast.success(data?.message)
      setDeletedArray([])
      getData()
    }
  }

  const getSettings = () => {
    get(`/admin/global/global-setting`).then((response) => {
      if (response?.status == true) {
        setDeliveryBannerImagePreview(`${response.imageUrl}${response?.data?.deliveryBanner}`)
      }
    })
  }

  return (
    <>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
      <CContainer fluid>
        <CRow>
          <CCol>
            <CCardGroup>
              <CCard className="p-4">
                <CHeader>
                  <div className="d-flex" style={{ flexDirection: 'column' }}>
                    <CCardSubtitle as="h4">Package Plan</CCardSubtitle>
                    {deletedArray.length > 0 && (
                      <button className="btn btn-danger mt-2 text-white" onClick={deleteOrders}>
                        Delete
                      </button>
                    )}
                  </div>
                  <CRow>
                    <CCol md="8">
                      <DefaultInputWithIcon
                        type={'text'}
                        placeholder="Search"
                        customType={'search'}
                        value={searchItem}
                        onClick={onSearchCLick}
                        onChange={(e) => {
                          onSearchCLick()
                          setsearchItem(e?.target?.value)
                        }}
                      />
                    </CCol>
                    <CCol md="4">
                      <Link
                        to={{ pathname: '/admin/subscription-plan/package/add' }}
                        className="btn btn-dark"
                      >
                        <CIcon icon={cilPlus} />
                        Add
                      </Link>
                    </CCol>
                  </CRow>
                </CHeader>
                <CCardBody>
                  <CTable bordered hover responsive>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell>S. No</CTableHeaderCell>
                        <CTableHeaderCell>Package Name (EN)</CTableHeaderCell>
                        <CTableHeaderCell>Package Name (TH)</CTableHeaderCell>
                        <CTableHeaderCell>Offer Applied</CTableHeaderCell>
                        <CTableHeaderCell>Status</CTableHeaderCell>
                        <CTableHeaderCell>Action</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>

                    <CTableBody>
                      {packageSubscription?.map((ele, index) => {
                        return (
                          <CTableRow key={index}  >
                            <CTableDataCell onClick={() => handleCheck(ele._id)}>
                              {pageData?.perPage * (pageData?.page - 1) + index + 1}
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  style={{ marginLeft: '2px', marginTop: '-20px' }}
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  checked={deletedArray?.includes(ele._id)}
                                // onChange={() => handleCheck(ele._id)}
                                />
                              </div>
                            </CTableDataCell>
                            <CTableDataCell>{ele?.packageName_EN}</CTableDataCell>
                            <CTableDataCell>{ele?.packageName_TH}</CTableDataCell>
                            <CTableDataCell style={{ color: '#d3dc40', fontWeight: 500 }}>
                              {ele?.applyoffer[0] != undefined
                                ? ele.applyoffer[0].couponCode
                                : '--'}
                            </CTableDataCell>
                            <CTableDataCell>
                              <CButton
                                size="sm"
                                outline={ele?.status ? '1' : '0'}
                                style={{
                                  backgroundColor: ele?.status ? '#00796B' : '#D32F2F',
                                  borderColor: ele?.status ? '#00796B' : '#D32F2F',
                                }}
                                onClick={() => statusChange(ele?.status, ele?._id)}
                              >
                                {ele?.status ? 'Active' : 'Inactive'}
                              </CButton>
                            </CTableDataCell>
                            <CTableDataCell>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserEdit(ele)}
                                style={{ fontSize: '20px', color: '#d3dc40', cursor: 'pointer' }}
                              >
                                <FaEdit />
                              </span>
                              <span
                                className="mx-2 text-danger"
                                onClick={() => deleteData(ele?._id)}
                                style={{ fontSize: '20px', padding: '5px', cursor: 'pointer' }}
                              >
                                <FaTrash />
                              </span>
                              <span
                                className="mx-2"
                                onClick={() => onClickUserView(ele)}
                                style={{
                                  fontSize: '20px',
                                  color: '#d3dc40',
                                  padding: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                <FaRegEye />
                              </span>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                  </CTable>
                  {!packageSubscription?.length && (
                    <>
                      <h1>No Data</h1>
                    </>
                  )}
                  {packageSubscription?.length ? (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={pageData?.page}
                      itemsCountPerPage={pageData?.perPage}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={changePage}
                    />
                  ) : (
                    ''
                  )}
                  <CRow>
                    <CCol md={6}>
                      <CFormLabel>Delivery Banner</CFormLabel>
                      {/* <span className='text-danger' style={{ marginLeft: '5px' }}>*</span> */}
                      <DefaultInput
                        type="file"
                        placeholder="Image"
                        accept="image/*"
                        refr={deliveryBannerFileRef}
                        onChange={(e) => {
                          const imageFile = e?.target?.files[0];
                          // If no file is selected, return early.
                          if (!imageFile) return;
                          // Validate file extension
                          const isValidImage = imageFile?.name?.match(/\.(jpg|jpeg|png|gif)$/);
                          if (!isValidImage) {
                            setInvalidDeliveryBannerImageNew('Please select a valid image');
                            return; // Return early to avoid further processing
                          } else {
                            setInvalidDeliveryBannerImageNew('');
                          }
                          // Update button disable state based on file name comparison
                          if (deliveryBannerImageUrl === imageFile.name) {
                            setIsDisable(false);
                          } else {
                            setIsDisable(true);
                          }

                          // Call the handler to upload the image
                          deliveryBannerHandler(imageFile);

                          // Image preview logic
                          const reader = new FileReader();
                          reader.onload = () => setDeliveryBannerImagePreview(reader.result); // Set preview once loaded
                          reader.onerror = (error) => console.error('Error reading file:', error); // Log errors
                          reader.readAsDataURL(imageFile);
                        }}
                      // onBlur={() => props?.setFieldTouched('deliveryBanner', true)}
                      // error={
                      //   (props?.touched?.deliveryBanner && props?.errors?.deliveryBanner) ||
                      //   invalidDeliveryBannerImageNew
                      // }
                      />

                    </CCol>
                    {diveryBannerImagePreview?.length > 0 ?
                      <>
                        <CCol>
                          <a target='_blank' href={`${diveryBannerImagePreview}`}>
                            <CImage
                              rounded
                              thumbnail
                              className="mt-2"
                              src={`${diveryBannerImagePreview}`}
                              style={{
                                maxHeight: '80px',
                                maxWidth: '80px',
                                alignItems: 'center',
                              }}
                            />
                          </a>
                        </CCol>
                      </>
                      : ''}
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default index
