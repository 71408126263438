import instance from './inteceptor'
import { useDispatch, useSelector } from 'react-redux'
import { store } from 'src/reduxToolkit/store/store'

export const post = async (url, formData, type = 0) => {
  const options = {
    headers: {
      Accept: type ? 'multipart/form-data' : 'application/json',
      'Content-Type': type ? 'multipart/form-data' : 'application/json',
    },
  }

  const token = store.getState()
  //  console.log(token)

  if (token?.auth?.token) {
    options.headers.Authorization = `Bearer ${token?.auth?.token}`
  }
  console.log(options);
  return instance
    .post(url, formData, options)
    .then(function (response) {
      // console.log("success", response);
      return response.data
    })
    .catch(function (error) {
      //   ShowToast(error.message, 0);
      if (error.message == 'Request failed with status code 401') {
        localStorage.clear()
        location.reload()
      }
    })
}
export const put = async (url, formData, type = 0) => {
  const options = {
    headers: {
      Accept: type ? 'multipart/form-data' : 'application/json',
      'Content-Type': type ? 'multipart/form-data' : 'application/json',
    },
  }
  const token = store.getState()

  if (token?.auth?.token) {
    options.headers.Authorization = `Bearer ${token?.auth?.token}`
  }
  return instance
    .put(url, formData, options)
    .then(function (response) {
      // console.log("success", response);
      return response.data
    })
    .catch(function (error) {
      // ShowToast(error.message, 0);
      if (error.message == 'Request failed with status code 401') {
        localStorage.clear()
        location.reload()
      }
    })
}
export const get = async (url, type = 0) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const token = store.getState()

  if (token?.auth.token) {
    options.headers.Authorization = `Bearer ${token?.auth?.token}`
  }

  return instance
    .get(url, options)
    .then(function (response) {
      // console.log("success", response);
      return response.data
    })
    .catch(function (error) {
      // console.log(error, "error");
      // ShowToast(error.message, 0);
      // if (error.message == "Request failed with status code 401") {
      //   localStorage.clear();
      //   location.reload();
      // }
      return error
    })
}
export const deleteApi = async (url, type = 0) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const token = store.getState()

  if (token?.auth?.token) {
    options.headers.Authorization = `Bearer ${token?.auth?.token}`
  }

  return instance
    .delete(url, options)
    .then(function (response) {
      // console.log("success", response);
      return response.data
    })
    .catch(function (error) {
      // ShowToast(error.message, 0);
      if (error.message == 'Request failed with status code 401') {
        localStorage.clear()
        location.reload()
      }
    })
}
