import {
  ConstantMessage,
  checkIfFilesAreCorrectType,
  checkIfFilesAreTooBig,
} from '../constants/validation'
import * as yup from 'yup'
// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const validateChangePass = (values) => {
  const errors = {}
  const warning = ConstantMessage.changePassword

  if (!values.currentPassword) {
    errors.currentPassword = warning.currentPassword
  }

  if (!values.newPassword) {
    errors.newPassword = warning.newPassword
  } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$/.test(values.newPassword)) {
    errors.newPassword = warning.strongPassword
  }

  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = warning.confirmNewPassword
  } else if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = warning.passmisMatched
  }

  return errors
}
export const validateProfile = (values) => {
  const errors = {}
  const warning = ConstantMessage.profile

  if (!values.Name) {
    errors.Name = warning.Name
  }
  if (!values.mobile) {
    errors.mobile = warning.mobile
  }

  return errors
}
export const validateExpert = (values, id) => {
  const errors = {}
  const warning = ConstantMessage.expert

  if (!values.Name) {
    errors.Name = warning.Name
  }

  if (!values.email) {
    errors.email = warning.email
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = warning.emailInvalid
  }

  // if (!values.mobile) {
  //   errors.mobile = warning.mobile;
  // }

  if (!values.experience) {
    errors.experience = warning.experience
  }

  if (!id) {
    if (!values.password) {
      errors.password = warning.password
    } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(values.password)) {
      errors.password = warning.strongPassword
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = warning.confirmPassword
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = warning.passmisMatched
    }
  }
  return errors
}
export const validateCustomer = (values, id) => {
  const errors = {}
  const warning = ConstantMessage.expert

  if (!values.Name) {
    errors.Name = warning.Name
  }

  if (!values.email) {
    errors.email = warning.email
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = warning.emailInvalid
  }

  if (!id) {
    if (!values.password) {
      errors.password = warning.password
    } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(values.password)) {
      errors.password = warning.strongPassword
    }
  }
  return errors
}
export const signUp = yup.object().shape({
  username: yup.string().required('User name required').trim(),
  email: yup.string().email('Invalid email').required('Email required'),
  // password: yup
  //   .string()
  //   .required('Password required')
  //   .matches(
  //     /^.(?=.*[0-9])(?=.*[a-z]).*$/,
  //     // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //     // 'Password must contain at least 8 characters, one uppercase, one number and one special case character',
  //     'Password must contain at least 8 characters with numbers is enough.',
  //   ),
  password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters and Number ',
    ),
  mobile: yup.string().required('Mobile number required').trim().max(15),
  // image: yup.string().required('Image is required'),
})

export const edit = yup.object().shape({
  username: yup.string().required('User name required').trim(),
  email: yup.string().email('Invalid email').required('Email required'),
  mobile: yup.string().required('Mobile number required').trim().max(15),
  // newPassword: yup
  //   .string()
  //   .matches(
  //     /^.(?=.*[0-9])(?=.*[a-z]).*$/,
  //     'New Password must contain at least 8 characters with numbers is enough.',
  //   ),
  newPassword: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters and Number ',
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'New password and confirm password do not match')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters and Number ',
    ),
  // .matches(
  //   /^.(?=.*[0-9])(?=.*[a-z]).*$/,
  //   'Confirm Password must contain at least 8 characters with numbers is enough.',
  // ),
})
export const validateSubAdmin = yup.object().shape({
  username: yup.string().required('User name required').trim(),
  email: yup.string().email('Invalid email').required('Email required'),
  mobile: yup.string().required('Mobile number required').trim().max(15),
})
// export const validatePendingStatusItem = yup.object().shape({
//   // services: yup.array().required("Service is required"),
//   price: yup.number().required("Price is required"),
// });
// export const validateRejectedStatusItem = yup.object().shape({
//   description: yup.string().required('Description is required').trim(),
// });
// export const validateConfirmedStatusItem = yup.object().shape({
//   // services: yup.array().required("Service is required"),
// });
export const editTemplate = yup.object().shape({
  title: yup.string().required('Title required').trim(),
  subject: yup.string().required('Subject required').trim(),
  description: yup.string().required('Description required').trim(),
})
export const settingsData = yup.object().shape({
  title: yup.string().required('Title Required').trim(),
  slug: yup.string().required('Slug Required').trim(),
  // logoImage:yup.string().required('Image is required'),
  // bannerImage:yup.string().required('Image is required'),
  email: yup.string().email('Invalid email').required('Email required'),
  metaTitle: yup.string().required('Meta Title Required').trim(),
  metaDescription: yup.string().required('Meta Description Required').trim(),
  address: yup.string().required('Address Required').trim(),
  footerDescription_EN: yup.string().required('Footer Description Required').trim(),
  footerDescription_TH: yup.string().required('Footer Description Required').trim(),
  mobile: yup.string().required('Mobile number required').trim().min(7).max(15),
  googlePlayStore: yup
    .string()
    .required('Url Required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid url',
    ),
  appStore: yup
    .string()
    .required('Url Required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid url',
    ),
  refer_user_point: yup.string().required('Refer User Point Required'),
  referral_user_point: yup.string().required('Refferal User Point Required').trim(),
  regularTitle_EN: yup.string().required('Regular Title Required').trim(),
  regularTitle_TH: yup.string().required('Regular Title Required').trim(),
  regularSubTitle_EN: yup.string().required('Regular Sub Title Required').trim(),
  regularSubTitle_TH: yup.string().required('Regular Sub Title Required').trim(),
  packageTitle_EN: yup.string().required('Package Title Required').trim(),
  packageTitle_TH: yup.string().required('Package Title Required').trim(),
  packageSubTitle_EN: yup.string().required('Packakge Sub Title Required').trim(),
  packageSubTitle_TH: yup.string().required('Packakge Sub Title Required').trim(),
  startPickUpTime: yup.string().required('Start Pickup Time Required').trim(),
  endPickUpTime: yup.string().required('End Pickup Time Required').trim(),
  nextPickupTime: yup.string().required('Next Pickup Time Required').trim(),
  deliveryFee: yup.number().required("Delivery Fee required").positive().integer(),
  packageItemOff: yup.number().required("Package item off required").positive().integer(),
  damageItemTime: yup.number().required("Damage/Stain item time required").positive().integer(),
  minimumOrderPrice: yup.number().required("Minimum order price required").positive().integer(),
  deliveryStartTime: yup.string().required("Delivery start time is required"),
  deliveryEndTime: yup.string().required("Delivery end time is required")
})
export const intro = yup.object().shape({
  title_en: yup.string().required('English Title Required').trim(),
  title_th: yup.string().required('Thai Title Required').trim(),
  image: yup.string().required('Image is required'),
  description_en: yup.string().required('English Description Required').trim(),
  description_th: yup.string().required('Thai Description Required').trim(),
})
export const serviceProcess = yup.object().shape({
  title_en: yup.string().required('English Title Required').trim(),
  title_th: yup.string().required('Thai Title Required').trim(),
  serviceId: yup.string().required('Service is Required').trim(),
  image: yup.string().required('Image is required'),
  description_en: yup.string().required('English Description Required').trim(),
  description_th: yup.string().required('Thai Description Required').trim(),
})
export const validateCMS = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  description_EN: yup.string().required('English Description Required').trim(),
  description_TH: yup.string().required('Thai Description Required').trim(),
})
export const introEdit = yup.object().shape({
  title_en: yup.string().required('Title Required').trim(),
  title_th: yup.string().required('Title Required').trim(),
  description_en: yup.string().required('English Description Required').trim(),
  description_th: yup.string().required('Thai Description Required').trim(),
})
export const serivceProcessEdit = yup.object().shape({
  title_en: yup.string().required('Title Required').trim(),
  title_th: yup.string().required('Title Required').trim(),
  serviceId: yup.string().required('Service Required').trim(),
  description_en: yup.string().required('English Description Required').trim(),
  description_th: yup.string().required('Thai Description Required').trim(),
})
export const validateBlog = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  metaTitle_EN: yup.string().required('Meta Title Required').trim(),
  metaTitle_TH: yup.string().required('Meta Title Required').trim(),
  metaKey_EN: yup.string().required('Meta Key Required').trim(),
  metaKey_TH: yup.string().required('Meta Key Required').trim(),
  imgtext_EN: yup.string().required('Image content Required').trim(),
  imgtext_TH: yup.string().required('Image content Required').trim(),
  blogWriter_EN: yup.string().required('Writer Name Required').trim(),
  blogWriter_TH: yup.string().required('Writer Name Required').trim(),
  image: yup.string().required('Image is required'),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  metaDescription_EN: yup.string().required('Meta Description Required').trim(),
  metaDescription_TH: yup.string().required('Meta Description Required').trim(),
})

export const validateBlogEdit = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  metaTitle_EN: yup.string().required('Meta Title Required').trim(),
  metaTitle_TH: yup.string().required('Meta Title Required').trim(),
  metaKey_EN: yup.string().required('Meta Key Required').trim(),
  metaKey_TH: yup.string().required('Meta Key Required').trim(),
  imgtext_EN: yup.string().required('Image content Required').trim(),
  imgtext_TH: yup.string().required('Image content Required').trim(),
  blogWriter_EN: yup.string().required('Writer Name Required').trim(),
  blogWriter_TH: yup.string().required('Writer Name Required').trim(),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  metaDescription_EN: yup.string().required('Meta Description Required').trim(),
  metaDescription_TH: yup.string().required('Meta Description Required').trim(),
})
export const validateFaq = yup.object().shape({
  question_EN: yup.string().required('Question Required').trim(),
  question_TH: yup.string().required('Question Required').trim(),
  answer_EN: yup.string().required('Answer Required').trim(),
  answer_TH: yup.string().required('Answer Required').trim(),
})
export const validateArea = yup.object().shape({
  areaName_EN: yup
    .string()
    .required('Area Name Required')
    .trim()
    .max(50, 'Maximum area length is 50'),
  areaName_TH: yup
    .string()
    .required('Area Name Required')
    .trim()
    .max(50, 'Maximum area length is 50'),
  // city_id: yup.string().required('City required'),
  // zipcode: yup.number().typeError('You must specify a number').required("This field is requried"),
  lat_long: yup.string().required('Area Range Required').trim(),
})
export const validateEditedArea = yup.object().shape({
  areaName_EN: yup
    .string()
    .required('Area Name Required')
    .trim()
    .max(50, 'Maximum area length is 50'),
  areaName_TH: yup
    .string()
    .required('Area Name Required')
    .trim()
    .max(50, 'Maximum area length is 50'),
  // zipcode: yup.number().typeError('You must specify a number').required("This field is requried"),
  lat_long: yup.string().required('Area Range Required').trim(),
})

export const validateAddress = yup.object().shape({
  address: yup.string().required('Address Required').trim(),
  area_id: yup.string().required('Area Name Required').trim(),
  // city_id: yup.string().required('City Name Required').trim(),
  // state_id: yup.string().required('State Name Required').trim(),
  type: yup.string().required('Address type Required'),
  // zipcode: yup.number().typeError('You must specify a zip code').required("This field is requried"),
})

export const validateState = yup.object().shape({
  stateName_EN: yup
    .string()
    .required('State Name Required')
    .trim()
    .max(50, 'Maximum state length is 50'),
  stateName_TH: yup
    .string()
    .required('State Name Required')
    .trim()
    .max(50, 'Maximum state length is 50'),
})

export const validateCity = yup.object().shape({
  state_id: yup.string().required('State Name Required').trim(),
  cityName_EN: yup
    .string()
    .required('City Name Required')
    .trim()
    .max(50, 'Maximum city length is 50'),
  cityName_TH: yup
    .string()
    .required('City Name Required')
    .trim()
    .max(50, 'Maximum city length is 50'),
})

export const validateZone = yup.object().shape({
  zonename: yup.string().required('Zone Name Required').trim(),
  areazone: yup.string().ensure().required('Area Zone required'),
})
export const validateEditZone = yup.object().shape({
  zonename: yup.string().required('Zone Name Required').trim(),
})

export const validateVan = yup.object().shape({
  vanNo: yup
    .string()
    .required('Van No Required')
    // .matches(
    //   /^[A-Z]{1,3}-[A-Z]{1,2}-[0-9]{1,4}&/,
    //   // /^([A-Z]{2}\d{4})|(\d{1}[A-Z]{2}\d{4})$/,
    //   'Van no must contain alphabet must be latest 2 letters and numbers must be at least 1 and not more than 5',
    // )
    .max(7),
  registrationNo: yup.string().required('Registration Number is required'),
  // vehicleModal: yup.string().required('Vehicle Modal is required'),
  // insuranceValidity: yup.string().required('Insurance Validiatity is required '),
  // registartionValidity: yup.string().required('Registration Validiatity is required '),
})
export const validateEditVan = yup.object().shape({
  vanNo: yup
    .string()
    .required('Van No Required')
    // .matches(
    //   /^([A-Z]{2}\d{4})|(\d{1}[A-Z]{2}\d{4})$/,
    //   'Van no must contain at least 6 or 7 characters,two uppercase,and four number digits',
    // )
    .max(7),
  registrationNo: yup.string().required('Registration Number is required'),
  // vehicleModal: yup.string().required('Vehicle Modal is required'),
})

export const validateUnit = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  image: yup.string().required('Image is required'),
})
export const validateEditUnit = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
})
export const validateItemSize = yup.object().shape({
  itemSize_EN: yup.string().required('Item Size Required').trim(),
  itemSize_TH: yup.string().required('Item Size Required').trim(),
})
export const validateReason = yup.object().shape({
  reason: yup.string().required('Reason Required').trim(),
  reasonType: yup.string().required('Reason type required'),
})
export const validateItemCategory = yup.object().shape({
  itemName_EN: yup.string().required('Item Name Required').trim(),
  itemName_TH: yup.string().required('Item Name Required').trim(),
})
export const validateItem = yup.object().shape({
  itemName_EN: yup
    .string()
    .required('Item name required')
    .trim()
    .max(50, 'Maximum item name length is 50'),
  itemName_TH: yup
    .string()
    .required('Item name required')
    .trim()
    .max(50, 'Maximum item name length is 50'),
  price: yup.string().required('Item price required').trim(),
  offer: yup.string().required('Item offer required').trim(),
  itemQuota: yup.string().required('Item Quota required').trim(),
  image: yup.string().required('Image is required'),
  itemSize: yup.array().required('Item size required'),
  itemCategory: yup.string().required('Item category required'),
})
export const validateProcess = yup.object().shape({
  processName_EN: yup.string().required('Process name required'),
  processName_TH: yup.string().required('Process name required'),
  image: yup.string().required('Image is required'),
})
export const validateProcessEdit = yup.object().shape({
  processName_EN: yup.string().required('Process name required'),
  processName_TH: yup.string().required('Process name required'),
})
export const validateAddOnService = yup.object().shape({
  serviceName_EN: yup.string().required('Service name required').trim(),
  serviceName_TH: yup.string().required('Service name required').trim(),
  description_EN: yup.string().required('Description required').trim(),
  description_TH: yup.string().required('Description required').trim(),
  image: yup.string().required('Image is required'),
  price: yup.number().required('Price is required'),
  fromdate: yup.string().required('From date is required'),
  todate: yup.string().required('To date is required'),
  addOnServiceQuantity: yup.number().required('Add on Service Quantity is required'),
})
export const validateEditAddOnService = yup.object().shape({
  serviceName_EN: yup.string().required('Service name required').trim(),
  serviceName_TH: yup.string().required('Service name required').trim(),
  description_EN: yup.string().required('Description required').trim(),
  description_TH: yup.string().required('Description required').trim(),
  price: yup.number().required('Price is required'),
  addOnServiceQuantity: yup.number().required('Add on Service Quantity is required'),
})
export const validateEditItem = yup.object().shape({
  itemName_EN: yup
    .string()
    .required('Item name required')
    .trim()
    .max(50, 'Maximum item name length is 50'),
  itemName_TH: yup
    .string()
    .required('Item name required')
    .trim()
    .max(50, 'Maximum item name length is 50'),
  price: yup.string().required('Item price required').trim(),
})
export const validatePackageDuration = yup.object().shape({
  title_EN: yup.string().required('Title Name Required').trim(),
  title_TH: yup.string().required('Title Name Required').trim(),
  duration: yup.string().required('Day Duration Required'),
})
export const validateRegularPlan = yup.object().shape({
  services: yup.array().required('Service is required'),
})
export const validateOfferValidation = yup.object().shape({
  serviceType: yup.string().required('Service type is required'),
  title_th: yup.string().required('Title is required'),
  offerAmount: yup.string().required('Offer amount is required'),
  title_en: yup.string().required('Title is required'),
  services: yup.array().required('Services required'),
  offerType: yup.string().required('Offer type is required'),
  minCart: yup.string().required('Minimum cart amount is required'),
  code: yup.string().required('Code is required'),
  fromDate: yup.string().required('From date is required'),
  todate: yup.string().required('To date is required'),
  quantity: yup.string().required('Value of offer quantity is required'),
  image_EN: yup.string().required('Image is required'),
  image_TH: yup.string().required('Image is required'),
  description_EN: yup.string().required('Description is required'),
  description_TH: yup.string().required('Description is required'),
})
export const validateOfferEditValidation = yup.object().shape({
  serviceType: yup.string().required('Service type is required'),
  title_th: yup.string().required('Title is required'),
  offerAmount: yup.string().required('Offer amount is required'),
  title_en: yup.string().required('Title is required'),
  services: yup.array().required('Services required'),
  offerType: yup.string().required('Offer type is required'),
  minCart: yup.string().required('Minimum cart amount is required'),
  code: yup.string().required('Code is required'),
  fromDate: yup.string().required('From date is required'),
  todate: yup.string().required('To date is required'),
  quantity: yup.string().required('Value of offer quantity is required'),
  description_EN: yup.string().required('Description is required'),
  description_TH: yup.string().required('Description is required'),
})
export const validateOrderNote = yup.object().shape({
  note_EN: yup.string().required('Note is required'),
  note_TH: yup.string().required('Note is required'),
  timingNote_EN: yup.string().required('Timing note is required'),
  timingNote_TH: yup.string().required('Timing note is required'),
  orderNote_EN: yup.string().required('Order note is required'),
  orderNote_TH: yup.string().required('Order note is required'),
  baseAmnt: yup
    .number()
    .typeError('You must specify a number')
    .required('Base amount for free delivery is required'),
  pickupGapTime: yup
    .number()
    .typeError('You must specify a number')
    .required('Pickup wait time is required'),
  deliveryGapTime: yup
    .number()
    .typeError('You must specify a number')
    .required('Delivery wait time is required'),
})
export const validatePackagePlan = yup.object().shape({
  // services: yup.string().required('Please select service'),
  // services: yup.array().required('Service is required'),
  // ApplyOffers: yup.array().required("Offer is required"),
  // item: yup.array().required('item is required'),
  packageName_EN: yup.string().required('Title Name Required').trim(),
  packageName_TH: yup.string().required('Title Name Required').trim(),
  // subcribeDuration:yup.string().required('Duration Price Required'),
  subcribeDuration: yup.string().required('Duration Price Required'),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  image: yup.string().required('Image is required'),
  small_pack_title_EN: yup.string().required('Small pack title EN is required'),
  small_pack_title_TH: yup.string().required('Small pack title TH is required'),
  small_pack_image: yup.string().required('Image is required'),
  medium_pack_title_EN: yup.string().required('Medium pack title EN is required'),
  medium_pack_title_TH: yup.string().required('Medium pack title TH is required'),
  medium_pack_image: yup.string().required('Image is required'),
  large_pack_title_EN: yup.string().required('Medium pack title EN is required'),
  large_pack_title_TH: yup.string().required('Medium pack title TH is required'),
  large_pack_image: yup.string().required('Image is required'),

  S_price: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Package Price must be number')
    .required('Package Price Required'),
  M_price: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Package Price must be number')
    .required('Package Price Required'),
  L_price: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Package Price must be number')
    .required('Package Price Required'),
  S_quantity: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Quantity Price must be number')
    .required('Quantity Required'),
  M_quantity: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Quantity Price must be number')
    .required('Quantity Required'),
  L_quantity: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Quantity Price must be number')
    .required('Quantity Required'),
  S_delivery: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Free Pickup And Delivery Price must be number'),
  M_delivery: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Free Pickup And Delivery Price must be number'),
  L_delivery: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Free Pickup And Delivery Price must be number'),
  // small_pack_description_EN: yup.string().required('Small Pack Description Required').trim(),
  // small_pack_description_TH: yup.string().required('Small Pack Description Required').trim(),
  // medium_pack_description_EN: yup.string().required('Medium Pack Description Required').trim(),
  // medium_pack_description_TH: yup.string().required('Medium Pack Description Required').trim(),
  // large_pack_description_EN: yup.string().required('Large Pack Description Required').trim(),
  // large_pack_description_TH: yup.string().required('Large Pack Description Required').trim(),
  // S_price: yup.string().required('Small package price Required').trim(),
  // S_quantity: yup.string().required('Small package quantity Required').trim(),
  // S_delivery: yup.string().required('Small package delivery Required').trim(),
  // // S_unit:yup.string().required('Unit Required for small package'),

  // M_price: yup.string().required('Medium package price Required').trim(),
  // M_quantity: yup.string().required('Medium package quantity Required').trim(),
  // M_delivery: yup.string().required('Medium package delivery Required').trim(),
  // // M_unit:yup.string().required('Unit Required for medium package'),

  // L_price: yup.string().required('Large package price Required').trim(),
  // L_quantity: yup.string().required('Large package quantity Required').trim(),
  // L_delivery: yup.string().required('Large package delivery Required').trim(),
  // L_unit:yup.string().required('Unit Required for large package'),
})
export const validateEditPackagePlan = yup.object().shape({
  // services: yup.array().required("Service is required."),
  packageName_EN: yup.string().required('Title Name Required').trim(),
  packageName_TH: yup.string().required('Title Name Required').trim(),
  // subcribeDuration:yup.string().required('Duration Price Required'),
  subcribeDuration: yup.string().required('Duration Price Required'),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  small_pack_title_EN: yup.string().required('Small pack title EN is required'),
  small_pack_title_TH: yup.string().required('Small pack title TH is required'),
  medium_pack_title_EN: yup.string().required('Medium pack title EN is required'),
  medium_pack_title_TH: yup.string().required('Medium pack title TH is required'),
  large_pack_title_EN: yup.string().required('Medium pack title EN is required'),
  large_pack_title_TH: yup.string().required('Medium pack title TH is required'),
  S_price: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Package Price must be number')
    .required('Package Price Required'),
  M_price: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Package Price must be number')
    .required('Package Price Required'),
  L_price: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Package Price must be number')
    .required('Package Price Required'),
  S_quantity: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Quantity Price must be number')
    .required('Quantity Required'),
  M_quantity: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Quantity Price must be number')
    .required('Quantity Required'),
  L_quantity: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Quantity Price must be number')
    .required('Quantity Required'),
  S_delivery: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Free Pickup And Delivery Price must be number'),
  M_delivery: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Free Pickup And Delivery Price must be number'),
  L_delivery: yup
    .number()
    .min(0, `Enter positive number`)
    .typeError('Free Pickup And Delivery Price must be number'),
  // packageName_EN: yup.string().required('Title Name Required').trim(),
  // packageName_TH: yup.string().required('Title Name Required').trim(),
  // subcribeDuration: yup.string().required('Duration Price Required'),
  // description_EN: yup.string().required('Description Required').trim(),
  // description_TH: yup.string().required('Description Required').trim(),
  // small_pack_description_EN: yup.string().required('Small Pack Description Required').trim(),
  // small_pack_description_TH: yup.string().required('Small Pack Description Required').trim(),
  // medium_pack_description_EN: yup.string().required('Medium Pack Description Required').trim(),
  // medium_pack_description_TH: yup.string().required('Medium Pack Description Required').trim(),
  // large_pack_description_EN: yup.string().required('Large Pack Description Required').trim(),
  // large_pack_description_TH: yup.string().required('Large Pack Description Required').trim(),
  // S_price: yup.string().required('Small package price Required').trim(),
  // S_quantity: yup.string().required('Small package quantity Required').trim(),
  // // S_delivery: yup.string().required('Small package delivery Required').trim(),
  // // S_unit:yup.string().required('Unit Required for small package'),

  // M_price: yup.string().required('Medium package price Required').trim(),
  // M_quantity: yup.string().required('Medium package quantity Required').trim(),
  // M_delivery: yup.string().required('Medium package delivery Required').trim(),
  // M_unit:yup.string().required('Unit Required for medium package'),

  // L_price: yup.string().required('Large package price Required').trim(),
  // L_quantity: yup.string().required('Large package quantity Required').trim(),
  // L_delivery: yup.string().required('Large package delivery Required').trim(),
  // L_unit:yup.string().required('Unit Required for large package'),
})
export const validateDriver = yup.object().shape({
  username: yup.string().required('Driver name required').trim().max(50),
  email: yup.string().email('Invalid email').required('Email required'),
  password: yup
    .string()
    .required('Password required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters and Number ',
    ),
  // .matches(
  //   /^.(?=.*[0-9])(?=.*[a-z]).*$/,
  //   'Password must contain at least 8 characters with numbers is enough.',
  //   // /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //   // 'Password must contain at least 8 characters, one uppercase, one number and one special case character',
  // ),
  mobile: yup.string().required('Mobile number required').trim().max(15),
  // image: yup.string().required('Image is required'),
  vanNo: yup.string().ensure().required('Van no required'),
  // city_id: yup.string().ensure().required('City name is Required'),
  areaName: yup.string().ensure().required('Area name required'),
})
export const validateServices = yup.object().shape({
  serviceName_EN: yup.string().required('Service Name Required').trim(),
  serviceName_TH: yup.string().required('Service Name Required').trim(),
  subTitle_EN: yup.string().required('Sub Title Required').trim(),
  subTitle_TH: yup.string().required('Sub Title Required').trim(),

  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  unit: yup.string().ensure().required('Unit Required'),
  item: yup.array().required('Item Required'),

  price: yup.string().required('Price Required').trim(),
  image: yup.string().required('Image is Required'),
  service_icon: yup.string().required('Service icon is Required'),
  userPolicy_EN: yup.string().required('Policy Required'),

  userPolicy_TH: yup.string().required('Policy Required'),
  // serviceQuota: yup.number().required('Service Quota Required'),
})
export const validateEditServices = yup.object().shape({
  serviceName_EN: yup.string().required('Service Name Required').trim(),
  serviceName_TH: yup.string().required('Service Name Required').trim(),
  subTitle_EN: yup.string().required('Sub Title Required').trim(),
  subTitle_TH: yup.string().required('Sub Title Required').trim(),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  // serviceQuota: yup.number().required('Service Quota Required'),
  // unit:yup.string().required('Unit Required').trim(),
  // item:yup.array().required('Item required'),
  price: yup.string().required('Price Required').trim(),
})
export const validateEditDriver = yup.object().shape({
  username: yup.string().required('Driver name required').trim().max(50),
  mobile: yup.string().required('Mobile number required').trim().max(15),
  vanNo: yup.string().required('Van no required'),
  // city_id: yup.string().required('City name is Required'),
  areaName: yup.array().ensure().required('Area name required'),
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters and Number ',
    ),
  // .matches(
  //   /^.(?=.*[0-9])(?=.*[a-z]).*$/,
  //   'New Password must contain at least 8 characters with numbers is enough.',
  // ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'New password and confirm password do not match')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters and Number ',
    ),
  // .matches(
  //   /^.(?=.*[0-9])(?=.*[a-z]).*$/,
  //   'Confirm Password must contain at least 8 characters with numbers is enough.',
  // ),
})
export const validateGlobalSetting = yup.object().shape({
  socialLink: yup
    .string()
    .required('Url Required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid url',
    ),
  image: yup.string().required('Image is required'),
})
export const validateEditGlobalSetting = yup.object().shape({
  socialLink: yup
    .string()
    .required('Url Required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid url',
    ),
})
export const slider = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  metaTitle_EN: yup.string().required('Meta Title Required').trim(),
  metaTitle_TH: yup.string().required('Meta Title Required').trim(),
  sliderUrl: yup
    .string()
    .required('Url Required')
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid url',
    ),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
})
// website: Yup.string()
//         .matches(
//             /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
//             'Enter correct url!'
//         )
//         .required('Please enter website'),
export const validateAbout = yup.object().shape({
  titleKey_EN: yup.string().required('Title key Required').trim(),
  titleKey_TH: yup.string().required('Title key Required').trim(),
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  aboutText1_EN: yup.string().required('write some text').trim(),
  aboutText1_TH: yup.string().required('write some text').trim(),
  aboutText2_EN: yup.string().required('write some text').trim(),
  aboutText2_TH: yup.string().required('write some text').trim(),
  // titleImage:yup.string().required('Image is required'),
  titleDescription_EN: yup.string().required('Title Description Required').trim(),
  titleDescription_TH: yup.string().required('Title Description Required').trim(),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
  advanceLaundaryProcessDescription_EN: yup
    .string()
    .required('Advance laundary process Description Required')
    .trim(),
  advanceLaundaryProcessDescription_TH: yup
    .string()
    .required('Advance laundary process Description Required')
    .trim(),
  // advanceLaundaryProcessImage:yup.string().required('advance laundary process Image is required'),
  personaliseLaundaryDecription_EN: yup
    .string()
    .required('Personlise landary description Required')
    .trim(),
  personaliseLaundaryDecription_TH: yup
    .string()
    .required('Personlise landary description Required')
    .trim(),
  // personaliseLaundaryImage:yup.string().required('Image is required'),
  machineChemicalTeachnologyDescription_EN: yup
    .string()
    .required('Machine And Chemical Technology Required')
    .trim(),
  machineChemicalTeachnologyDescription_TH: yup
    .string()
    .required('Machine And Chemical Technology Required')
    .trim(),
  // machineChemicalTeachnologyImage:yup.string().required('Image is required'),
})
export const validatePartners = yup.object().shape({
  title_EN: yup.string().required('Title Required').trim(),
  title_TH: yup.string().required('Title Required').trim(),
  description_EN: yup.string().required('Description Required').trim(),
  description_TH: yup.string().required('Description Required').trim(),
})

// App
export const appHomeAbout = yup.object().shape({
  title_en: yup.string().required('Title Required').trim(),
  title_th: yup.string().required('Title Required').trim(),
  description_en: yup.string().required('Description Required').trim(),
  description_th: yup.string().required('Description Required').trim(),
})

export const appHomeAboutUpdate = yup.object().shape({
  title_en: yup.string().required('Title Required').trim(),
  title_th: yup.string().required('Title Required').trim(),
  description_en: yup.string().required('Description Required').trim(),
  description_th: yup.string().required('Description Required').trim(),
})
export const appHelp = yup.object().shape({
  title_en: yup.string().required('Title Required').trim(),
  title_th: yup.string().required('Title Required').trim(),
})

export const appHelpUpdate = yup.object().shape({
  title_en: yup.string().required('Title Required').trim(),
  title_th: yup.string().required('Title Required').trim(),
})

export const appHomeSlider = yup.object().shape({
  enImage: yup.string().required('English image is required'),
  thImage: yup.string().required('Thai image is required'),
})

export const appHomeSliderUpdate = yup.object().shape({})

export const attributeValidation = yup.object().shape({
  attributeName_TH: yup.string().required('Attribute name is required'),
  attributeName_EN: yup.string().required('Attribute name is required'),
})

export const optionValidation = yup.object().shape({
  // attributeName: yup.string().required("Attribute is required"),
  attributeName_TH: yup.string().required('Attribute name is required'),
  attributeName_EN: yup.string().required('Attribute name is required'),
})

export const validateBulkQr = yup.object().shape({
  userId: yup.string().ensure().required('Driver is required'),
})
